import { render, staticRenderFns } from "./AddAccountSuccess.vue?vue&type=template&id=73dade43&scoped=true&"
import script from "./AddAccountSuccess.vue?vue&type=script&lang=ts&"
export * from "./AddAccountSuccess.vue?vue&type=script&lang=ts&"
import style0 from "./AddAccountSuccess.less?vue&type=style&index=0&id=73dade43&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73dade43",
  null
  
)

export default component.exports