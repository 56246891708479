


































































import { AddAccountSuccess } from './AddAccountSuccess';
export default AddAccountSuccess;
