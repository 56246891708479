import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export class AddAccountSuccess extends Vue {
    public panelTitle: string = '';

    @Prop({default: false})
    isMobile!: boolean;

    public backToHome(modal: string, home: boolean) {
      this.$emit('close', {goToHome: home, modal: modal});
      this.$router.push('/home');
    }

    public addNewProgram(){
      this.backToHome('add-account-success', false);
      this.$router.push('/account/programs');
    }
}